.b {
    width: 32px;
    height: 32px;
    background: url(/src/assets/images/gym_sprites.png) 64px 0;
    display: inline-block;
}

.d {
    width: 32px;
    height: 32px;
    background: url(/src/assets/images/gym_sprites.png) -96px 0;
    display: inline-block;
}

.k {
    width: 32px;
    height: 32px;
    background: url(/src/assets/images/gym_sprites.png) -32px 0;
    display: inline-block;
}

.m {
    width: 32px;
    height: 32px;
    background: url(/src/assets/images/gym_sprites.png) -64px 0;
    display: inline-block;
}

.w {
    width: 32px;
    height: 32px;
    background: url(/src/assets/images/gym_sprites.png) -160px 0;
    display: inline-block;
}

.App {
  background-color: #ebebeb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.copy {
  text-align: center;
}

.btn-social {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 100%;
  background-color: white;
}

@media only screen and (min-width: 1536px) {
  .btn-social {
    height: 3rem;
    width: 3rem;
    margin: 1rem !important;
    font-size: x-large;
  }
  .copy {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1536px) {
  .btn-social {
    height: 2rem;
    width: 2rem;
    margin: 1rem !important;
    font-size: medium;
  }

  .copy {
  font-size: 12px;
}
}

.footer {
  height: 15vh !important;
  position: sticky;
  bottom: 0;
  /* background-color: #b7b6b6; */
  display: flex;
  color: white;
}

.main {
  height: 85vh;
  position: relative;
}

.hidden {
  display: none;
}

.full-width {
  padding: 0;
}
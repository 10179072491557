.block-container {
    position: relative;
    margin-top: 1em;
}

.sprite-container {
    position: relative;
    display: inline-block;
    /* height: 200px;
    width: 100px; */
    width: 50%;
    padding-top: 100%;
}

.body-front {
    left: 0;
    top: 0;
    position: absolute;
    background-size: cover;
    image-rendering: pixelated;
    height: 100%;
    width: 100%;
}
.body-back {
    left: 0;
    top: 0;
    position: absolute;
    background-size: cover;
    image-rendering: pixelated;
    height: 100%;
    width: 100%;
    background-position-x: 100%;
}

.body-back.male,
.body-front.male {
    background-image: url(/src/assets/images/muscles/body_male.png);
}
.body-back.female,
.body-front.female {
    background-image: url(/src/assets/images/muscles/body_female.png);
}

.muscle-sprites {
    left: 0;
    top: 0;
    position: absolute;
    image-rendering: pixelated;
    height: 100%;
    width: 100%;
    background-size: cover;
}

.male-body-front-muscles {
    background-image: url(/src/assets/images/muscles/body_muscles_male_front.png);
}

.male-body-back-muscles {
    background-image: url(/src/assets/images/muscles/body_muscles_male_back.png);
}

.female-body-front-muscles {
    background-image: url(/src/assets/images/muscles/body_muscles_female_front.png);
}

.female-body-back-muscles {
    background-image: url(/src/assets/images/muscles/body_muscles_female_back.png);
}

/* FRONT MUSCLES */
.body-front-pectorals {
    background-position-x: -webkit-calc(100% / 9 * 0);
}

.body-front-abdominals {
    background-position-x: -webkit-calc(100% / 9 * 1);
}

.body-front-deltoids {
    background-position-x: -webkit-calc(100% / 9 * 2);
}

.body-front-traps {
    background-position-x: -webkit-calc(100% / 9 * 3);
}

.body-front-biceps {
    background-position-x: -webkit-calc(100% / 9 * 4);
}

.body-front-quadriceps {
    background-position-x: -webkit-calc(100% / 9 * 5);
}

.body-front-calves {
    background-position-x: -webkit-calc(100% / 9 * 6);
}

.body-front-obliques {
    background-position-x: -webkit-calc(100% / 9 * 7);
}

.body-front-triceps {
    background-position-x: -webkit-calc(100% / 9 * 8);
}

.body-front-forearms {
    background-position-x: -webkit-calc(100% / 9 * 9);
}

/* BACK MUSCLES */
.body-back-traps {
    background-position-x: -webkit-calc(100% / 9 * 0);
}

.body-back-lats {
    background-position-x: -webkit-calc(100% / 9 * 1);
}

.body-back-deltoids {
    background-position-x: -webkit-calc(100% / 9 * 2);
}

.body-back-triceps {
    background-position-x: -webkit-calc(100% / 9 * 3);
}

.body-back-glutes {
    background-position-x: -webkit-calc(100% / 9 * 4);
}

.body-back-hamstrings {
    background-position-x: -webkit-calc(100% / 9 * 5);
}

.body-back-calves {
    background-position-x: -webkit-calc(100% / 9 * 6);
}

.body-back-quadriceps {
    background-position-x: -webkit-calc(100% / 9 * 7);
}

.body-back-lower-back {
    background-position-x: -webkit-calc(100% / 9 * 8);
}

.body-back-forearms {
    background-position-x: -webkit-calc(100% / 9 * 9);
}

/* .male-body-back {    
    left: 0;
    top: 0;
    position: absolute;
    background: url(/src/assets/images/muscles/body_male.png);
    background-position-x: 100%;    
    background-size: cover;    
    image-rendering: pixelated;
    height: 100%;
    width: 100%;
} */

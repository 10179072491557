.login {
    /* height: 80vh;
    width: 80vw; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.login__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.login__btn {

    border: none;
    color: white;
    background-color: black;
}

@media only screen and (max-width: 1536px) {
    .header {
        font-size: medium;
    }

    /* .login div {
        margin-top: 2px;
    }

    .login_form input {
        padding: 4px 14px 4px 14px;
    }

    .login_text {
        margin: 10px !important;
    } */

}

@media only screen and (min-width: 1536px) {

    .login div {
        margin-top: 7px;
    }

    .header {
        font-size: large;
    }

    .login__container {
        padding: 30px;
        width: 25vw;
    }

    /* .login__textBox {
        padding: 10px;
        font-size: 18px;
        margin-bottom: 10px;
    } */

    /* .login_form input {
        font-size: 18px;
    } */

    .login__btn {
        padding: 10px;
        font-size: 18px;
        margin-bottom: 10px;
    }
}

.login__google {
    background-color: #4285f4;
}

.btn_login {
    text-transform: unset !important;
    height: 50px;
    width: 80%;
    align-self: center;
    margin-bottom: 1em !important;
}

.btn_social {
    background-color: white !important;
    color: black !important;
}

.login_form {
    width: 80% !important;
    margin-top: 7px !important;
}

.appstore img {
    /* width: 100%;
    height: auto; */
    /* display: block; */
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.appstore svg {
    height: 100%;
}

.appstore {
    margin: auto;
    width: 50%;
    /* display: flex; */
    height: 4em;
}

.appstorecontainer {
    display: flex;
    /* height: 4em; */
    padding-left: 2rem;
    padding-right: 2rem;
}

.errorbox {
    margin-bottom: 1em;
}